<script setup lang="ts">
definePageMeta({
  colorMode: 'dark',
  layout: 'parallax-video',
})

const { data: pageContent } = await useAsyncData('index', () => queryContent('pages/').findOne())

useSeoMeta({
  description: pageContent.value?.description,
  ogDescription: pageContent.value?.description,
  ogTitle: pageContent.value?.title,
})

 import.meta.prerender && defineOgImageComponent('LandingPage', {
  title: pageContent.value?.hero.title,
})
</script>

<template>
  <ULandingHero
    v-bind="pageContent?.hero"
    :ui="{
      title: 'font-smoke w-min m-auto font-normal tracking-normal text-6xl sm:text-8xl md:text-9xl lg:text-[12rem] dark:text-primary-200 headline-outline',
      description: 'font-serif font-semibold text-xl sm:text-2xl md:text-3xl lg:text-4xl dark:text-primary-100 text-pretty text-outline',
    }"
  />

  <ULandingSection
    v-bind="pageContent?.menu"
    :ui="{
      title: 'font-serif text-outline dark:text-primary-100'
    }"
  >
    <ULandingGrid id="menu" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)] max-w-screen-lg m-auto">
      <ULandingCard
        v-for="(card, index) in pageContent?.menu.cards"
        :key="index"
        v-bind="card"
        class="col-span-6"
        :ui="{
          title: 'font-serif'
        }"
      >
        <NuxtImg
          v-if="card.image"
          v-bind="card.image"
          sizes="xs:100vw sm:100vw md:100vw lg:50vw xl:50vw 2xl:50vw"
          class="w-full h-full object-contain"
        />
      </ULandingCard>
    </ULandingGrid>
  </ULandingSection>

  <ULandingSection
    v-bind="pageContent?.services"
    :ui="{
      title: 'font-serif text-outline dark:text-primary-100'
    }"
  >
    <UPageGrid id="services" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
      <ULandingCard
        v-for="(card, index) in pageContent?.services.cards"
        :key="index"
        v-bind="card"
        :ui="{
          title: 'font-serif text-outline dark:text-primary-100',
        }"
      />
    </UPageGrid>
  </ULandingSection>

  <ULandingSection class="dark:bg-primary-400">
    <ULandingCTA
      v-bind="pageContent?.contact"
      id="contact"
      :ui="{
        title: 'font-serif',
        links: 'flex flex-col gap-2'
      }"
    />
  </ULandingSection>

  <ULandingSection
    id="about"
    v-bind="pageContent?.about"
    align="center"
    :ui="{
      title: 'font-serif text-outline dark:text-primary-100',
    }"
  >
    <div
      v-for="(card, index) in pageContent?.about.cards"
      :key="index"
      class="grid grid-cols-1 lg:grid-cols-3 gap-8 mx-4 lg:mx-8"
    >
      <div class="col-span-2"  :class="{'lg:order-last': index % 2 === 1}">
        <UIcon :name="card.icon" class="size-10 text-primary-200"/>
        <h3 class="text-3xl font-serif font-semibold dark:text-primary-400 mb-2">
          {{ card.title }}
        </h3>
        <p class="text-lg text-[15px] dark:text-gray-300">{{ card.description }}</p>
      </div>

      <NuxtImg
        v-if="card.image"
        v-bind="card.image"
        sizes="xs:100vw sm:100vw md:100vw lg:50vw xl:50vw 2xl:50vw"
        class="w-full h-full object-contain"
      />
    </div>
  </ULandingSection>

  <ULandingSection>
    <ULandingCTA
      id="cta"
      v-bind="pageContent?.cta"
      :ui="{
        title: 'font-serif text-outline dark:text-primary-100'
      }"
    />
  </ULandingSection>
</template>
